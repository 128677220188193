<template>
    <v-container>
        <h5 class="text-h5 mb-5 primary--text">Tags</h5>
        <v-expand-transition>
          <v-alert
          v-show="alert"
          border="left"
          text
          :type="alertType"
          >
            {{alertText}}
          </v-alert>
        </v-expand-transition>
        <v-data-table :search="search" item-key="name" :loading="loading" :headers="headers" :items="tags" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Tags</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn small @click="$router.push({name: 'tagsView', params: {link: 'profile-tags'}})" color="primary" elevation="0" dark class="mb-2 mx-1">Profile</v-btn>
                  <v-btn small @click="$router.push({name: 'tagsView', params: {link: 'single-tags'}})" color="primary" elevation="0" dark class="mb-2 mx-1">Single</v-btn>
                  <v-btn small @click="$router.push({name: 'tagsView', params: {link: 'multiple-tags'}})" color="primary" elevation="0" dark class="mb-2 mx-1">Multiple</v-btn>
                  <v-dialog v-model="dialog" width="500px">
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="primary" elevation="0" dark class="mb-2" v-bind="attrs" v-on="on">
                              Add New Tag
                          </v-btn>
                      </template>
                      <v-card>
                          <v-card-title>
                              <span class="text-h5 primary--text">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                              <v-container>
                                  <v-text-field outlined v-model="editedItem.name" label="Tag Title"></v-text-field>
                                  <v-row no-gutters>
                                    <v-text-field outlined type="number" class="mr-1" :step="0.1" :min="0" v-model="editedItem.value" label="Tag Value"></v-text-field>
                                    <v-text-field outlined type="number" class="ml-1" :step="0.1" :min="0" v-model="editedItem.weight" label="Tag Weight"></v-text-field>
                                  </v-row>
                                  <v-select
                                  label="Tag Type"
                                  outlined
                                  v-model="editedItem.type"
                                  :items="['profile', 'single', 'multiple', 'direct']"
                                  ></v-select>
                              </v-container>
                          </v-card-text>

                          <v-card-actions>
                              <v-btn color="blue darken-1" text @click="close">
                                  Cancel
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn tile color="primary darken-1" elevation="0" @click="save"> Save </v-btn>
                          </v-card-actions>
                      </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                          <v-card-title class="text-h5 paragraph--text">Are you sure you want to delete this item?</v-card-title>
                          <v-divider class="my-3"></v-divider>
                          <v-card-actions>
                              <v-btn color="warning" text @click="closeDelete">Cancel</v-btn>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" tile elevation="0" @click="deleteItemConfirm">OK</v-btn>
                          </v-card-actions>
                      </v-card>
                  </v-dialog>
                  <v-dialog v-model="linkDialog" width="500px">
                    <v-card>
                      <v-card-title class="text-h6 paragraph--text">Link Tag to {{editedItem.type === 'profile' ? 'Profile' : 'Question'}}</v-card-title>
                      <v-divider class="my-3"></v-divider>
                      <v-card-text v-if="editedItem.type === 'profile'">
                        <h5 class="mb-3">{{editedItem.name}}</h5>
                        <v-select
                        label="Profile property"
                        outlined
                        v-model="linkTagWithProfile.profile_column"
                        item-text="text"
                        item-value="value"
                        :items="[{text: 'Religion', value: 'religion'}, {text: 'Marital status', value: 'marital_status'}, {text: 'Gender', value: 'gender'}]"
                        ></v-select>

                        <v-select
                        label="Profile value"
                        outlined
                        :disable="!linkTagWithProfile.profile_column"
                        v-model="linkTagWithProfile.value"
                        :items="profileProperties"
                        ></v-select>
                      </v-card-text>

                      <v-card-text v-else>
                        <h5 class="mb-3">{{editedItem.name}}</h5>
                        <v-select
                        label="Question property"
                        outlined
                        v-model="linkTagWithQuestions.question"
                        item-text="question"
                        item-value="id"
                        return-object
                        :items="allQuestions"
                        ></v-select>

                        <v-select
                        label="Profile value"
                        outlined
                        :disable="!linkTagWithQuestions.question"
                        item-text="Answer"
                        item-value="id"
                        return-object
                        v-model="linkTagWithQuestions.value"
                        :items="questionAnswers"
                        ></v-select>

                        <v-btn v-if="editedItem.type === 'multiple'" text color="primary" large @click="addToQuestionLinksList"><v-icon>mdi-plus-circle</v-icon>add another question</v-btn>

                        <div v-if="editedItem.type === 'multiple' && questionLinks.length">
                          <v-subheader>Questions</v-subheader>
                          <div v-for="(question, index) in questionLinks" :key="index">
                            <span>
                              - {{question.question.question}} - {{question.answer.Answer}}
                            </span>
                            <v-btn color="red" icon @click="removeFromQuestionLinksList(index)"><v-icon>mdi-delete</v-icon></v-btn>
                          </div>
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="warning" text @click="linkDialog = false">Cancel</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" tile elevation="0" @click="linkTag(editedItem)">Save</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="mx-5 mb-2"
                ></v-text-field>
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn text color="blue">{{item.id}}</v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon color="blue" class="mr-5" @click="editItem(item)"> mdi-pencil </v-icon>
              <v-icon color="error" class="mr-5" @click="deleteItem(item)"> mdi-delete </v-icon>
              <v-icon color="primary" v-if="item.type && item.type !== 'direct'" @click="linkItem(item)"> mdi-link-variant </v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import admin from '@/web_services/admin'
import user from '@/web_services/user'

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    alert: '',
    alertType: 'success',
    alertText: '',
    search: '',
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id'
      },
      {
        text: 'Title',
        align: 'start',
        sortable: true,
        filterable: true,
        value: 'name'
      },
      { text: 'Value', value: 'value', sortable: true, align: 'start' },
      { text: 'Weight', value: 'weight', sortable: true, align: 'start' },
      { text: 'Type', value: 'type', sortable: true, align: 'start' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'end' }
    ],
    tags: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      value: 0,
      weight: 0,
      type: 'direct'
    },
    defaultItem: {
      name: '',
      value: 0,
      weight: 0,
      type: 'direct'
    },
    linkTagWithProfile: {
      profile_column: '',
      value: ''
    },
    allQuestions: [],
    linkTagWithQuestions: {
      question: null,
      answer: null
    },
    questionLinks: [],
    linkDialog: false,
    loading: true
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Tag' : 'Edit Tag'
    },
    questionAnswers () {
      if (this.linkTagWithQuestions.question) {
        return this.allQuestions.find(question => question.id === this.linkTagWithQuestions.question.id).answers
      } else {
        return []
      }
      // const question = this.allQuestions.find(q => q.id === this.linkTagWithQuestions.question.id)
      // if (question) return question.answers
      // else return []
    },
    profileProperties () {
      let profileProperties = []
      switch (this.linkTagWithProfile.profile_column) {
        case 'religion':
          profileProperties = ['Christian', 'Muslim', 'Hindu', 'Buddhist', 'Other']
          break
        case 'marital_status':
          profileProperties = ['Single', 'Married', 'Divorced', 'Widowed']
          break
        case 'gender':
          profileProperties = ['Male', 'Female', 'Not Defined', 'Prefer not to say']
          break
      }
      return profileProperties
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  methods: {
    editItem (item) {
      this.editedIndex = this.tags.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.tags.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    linkItem (item) {
      this.editedIndex = this.tags.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (item.type === 'profile') {
        this.linkTagWithProfile.profile_column = item.profile_attribute.profile_column
        this.linkTagWithProfile.value = item.profile_attribute.value
      }
      this.linkDialog = true
    },

    addToQuestionLinksList () {
      this.questionLinks.push({
        question: this.linkTagWithQuestions.question,
        answer: this.linkTagWithQuestions.value
      })
      this.linkTagWithQuestions = {
        question: null,
        value: null
      }
    },

    removeFromQuestionLinksList (index) {
      this.questionLinks.splice(index, 1)
    },

    linkTag (tag) {
      if (tag.type === 'profile') {
        admin.linkTagWithProfile(tag.id, this.linkTagWithProfile).then(response => {
          this.showAlert('success', 'Tag linked successfully')
          tag.profile_attribute = response.data.data
          this.tags[this.tags.findIndex(item => item.id === tag.id)].profile_attribute = response.data.data
          this.linkDialog = false
        }).catch(() => {
          this.showAlert('success', 'Tag linked successfully')
          this.linkDialog = false
        })
      } else {
        const payload = {
          answerIds: []
        }
        if (tag.type === 'multiple') {
          payload.answerIds = this.questionLinks.map(question => question.answer.id)
          if (this.linkTagWithQuestions.question) {
            payload.answerIds.push(this.linkTagWithQuestions.value.id)
          }
        } else {
          payload.answerIds = [this.linkTagWithQuestions.value.id]
        }
        admin.linkTagWithQuestion(tag.id, payload).then(response => {
          this.showAlert('success', 'Tag linked successfully')
        }).catch(() => {
          this.showAlert('error', 'Error while linking tag')
        }).finally(() => {
          this.linkTagWithQuestions = {
            question: null,
            value: null
          }
          this.linkDialog = false
        })
      }
    },

    deleteItemConfirm () {
      this.loading = true
      admin.deleteTripTag(this.editedItem.id).then(res => {
        // this.tags.splice(this.editedIndex, 1)
        this.initializeTags()
      }).catch((error) => {
        let text = ''
        if (error.response.data.errors) {
          Object.entries(error.response.data.errors).forEach(([key, value]) => {
            value.forEach(item => {
              text += item + ' ! '
            })
          })
        } else text = error.response.data.message
        this.showAlert('error', text)
      }).finally(() => {
        this.loading = false
      })
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      this.loading = true
      //  edit item
      if (this.editedIndex > -1) {
        admin.updateTripTag(this.editedItem.id, this.editedItem).then(res => {
          // this.tags[this.editedIndex] = res.data.data
          this.initializeTags()
        }).catch((error) => {
          let text = ''
          if (error.response.data.errors) {
            Object.entries(error.response.data.errors).forEach(([key, value]) => {
              value.forEach(item => {
                text += item + ' ! '
              })
            })
          } else text = error.response.data.message
          this.showAlert('error', text)
        }).finally(() => {
          this.loading = false
        })
      } else {
        // add item
        let notRepeatedTag = true
        this.tags.forEach(tag => {
          if (tag.name.toLowerCase() === this.editedItem.name.toLowerCase()) notRepeatedTag = false
        })
        if (notRepeatedTag) {
          admin.addTripTag(this.editedItem).then(res => {
            this.tags.unshift(res.data.data)
          }).catch((error) => {
            let text = ''
            if (error.response.data.errors) {
              Object.entries(error.response.data.errors).forEach(([key, value]) => {
                value.forEach(item => {
                  text += item + ' ! '
                })
              })
            } else text = error.response.data.message
            this.showAlert('error', text)
          }).finally(() => {
            this.loading = false
          })
        } else {
          this.loading = false
          this.showAlert('warning', 'This tag already exists!')
        }
      }
      this.close()
    },

    showAlert (type, message) {
      this.alertText = message
      this.alertType = type
      this.alert = true
      window.scrollTo(0, 0)
      setTimeout(() => {
        this.alert = false
      }, 3000)
    },

    initializeTags () {
      admin.tripTags().then(res => {
        const names = res.data.data.map(el => el.name)
        this.tags = res.data.data.filter(({ name }, index) => !names.includes(name, index + 1))
        console.log(this.tags)
      }).catch(() => {
        this.tags = []
      }).finally(() => {
        this.loading = false
      })
    }
  },

  created () {
    this.initializeTags()
    user.interestQuestions().then(res => {
      this.allQuestions = res.data.data
    }).catch(error => {
      this.$emit('error', error)
    })
  }
}
</script>

<style>
td {
  font-size: 20px !important;
}
</style>
